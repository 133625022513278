import * as React from 'react';

import { WidgetDetails, Links, BotDetails } from '../types/common-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Link, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogContentText from '@mui/material/DialogContentText';

interface PopupProps {
  showPopup: boolean;
  handleClose: () => void
  botDetails: BotDetails | null;
}
export default function Popup(props: PopupProps) {

  const { botDetails, showPopup, handleClose } = props
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  function truncateText(text: string, maxLength: number) {
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
  }
  const styles = {
    dialogStyles: {
      maxWidth: '500px',
      minWidth: '400px',
      borderRadius: '20px',
      backgroundColor: 'background.default',
      backgroundImage: 'none !important'
    },
    dialogContentBoxStyles: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      m: 0,
      p: 0,
    },
    dialogBgBoxStyles: {
      width: '100%',
      height: '130px',
      backgroundColor: 'text.primary',
      margin: 0,
      padding: 0,
    },
    dialogImageStyles:(theme: any) => ({
      backgroundColor: 'background.default',
      padding: 1,
      borderRadius: '50%',
      margin: 'auto',
      mt: '-66px',
      width: 120,
      height: 120,
      boxShadow:  theme.palette.mode === 'light' ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : '0px 4px 8px rgba(255, 255, 255, 0.1)'
    }),
    dialogHeadingStyles: {
      pt: 3,
      mx: 2,
      textAlign: 'center',
      color: 'text.secondary',
      width: '87%',
      margin: 'auto',
      fontWeight: 'bold',
      fontSize: '32px'
    },
    dialogSubheadingStyles: {
      pt: 2,
      mx: 4,
      textAlign: 'center',
      color: 'text.secondary',
      width: '80%',
      margin: 'auto'
    },
    dialogButtonStyles: {
      bgcolor: 'text.primary',
      color: 'white',
      '&:hover': {
        bgcolor: 'rgba(6, 146, 226, 0.9)',
        color: 'white',
      },
    },
    dialogDividerStyles: {
      mb: 2,
      mx: 3,
      borderColor: '#D3D3D3'
    },
    dialogFooterTextStyles: {
      textAlign: 'center',
      fontSize: 'small',
      color: 'text.secondary',
      mb: "5px"
    },

    linkTextStyles: {
      mb: 4,
      textAlign: 'center',
      fontSize: '12px',
      color: 'text.primary'
    }

  }

  const handleLinkClick = (value: string) => {
    window.open(value, '_blank');
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={showPopup}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: styles.dialogStyles
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Box
            sx={styles.dialogContentBoxStyles}
          >
            <Box
              sx={styles.dialogBgBoxStyles}
            />
            <Divider />
            <Box
              sx={styles.dialogImageStyles}
              component={'img'}
              alt="Bot Icon"
              src={botDetails?.botImage ? botDetails?.botImage : "/static/robot.png"}
            />
            <DialogContentText sx={styles.dialogHeadingStyles}>
              {botDetails?.botHeading !== "Hello! I am your Bot." ? truncateText(` ${botDetails?.botHeading}`, 50)
                :
                <>Hello! I am your <span style={{ color: 'text.primary' }}>{botDetails?.botTitle}.</span></>

              }
            </DialogContentText>
            <DialogContentText sx={styles.dialogSubheadingStyles}>
              {botDetails?.botSubheading ? truncateText(` ${botDetails?.botSubheading}`, 90)
                :
                <>Let me assist you in your research. Click on Get Started to start exploring.</>

              }
            </DialogContentText>
          </Box>
        </DialogContent>

        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 5 }}>
          <Button
            onClick={handleClose}
            sx={styles.dialogButtonStyles}
          >
            Get Started
          </Button>
        </DialogActions>
        <Divider sx={styles.dialogDividerStyles} />
        <Box sx={{display:'flex', width:'100%', justifyContent:'center', gap:0.5, mb: 2, }} >
        <Typography sx={theme => ({
          
          textAlign: 'center', 
          fontSize: 'small', 
          color: theme.palette.text.secondary,
        })}>
          Powered by 
        </Typography>
        <Box
        component={"img"}
        src={theme.palette.mode === "light" ? '/static/Apex_Logo_2022_large.png' : '/static/Apex_Logo_2022_Large2.png'}
        width={"35px"}
        height={"10px"}
        marginTop={0.4}
        />
        </Box>
        <Box sx={{ display: "flex", gap: 1, justifyContent: 'center' }}>
          {botDetails?.botLinks?.map((link: Links) =>
            <Link
              key={link.title}
              href={link.value}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick(link.value)}
              underline="hover"
              sx={styles.linkTextStyles}
            >
              {link.title}
            </Link>
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
