import * as React from "react";

import Hero from "./components/Hero";
import Popup from "./components/Popup";
import AppAppBar from "./components/AppAppBar";
import { WidgetDetails, Response, BotDetails } from "./types/common-types";

import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

interface BotProps {
  prompt: string;
  showPopup: boolean;
  isLoading: boolean;
  responses: Response[];
  currentStream: string;
  handleClose: () => void;
  handleSubmit: () => void;
  isStreamLoading: boolean;
  botDetails: BotDetails | null;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  theme: any;
  handleRefreshResponse: () => void;
}

export default function Bot(props: BotProps) {
  const {
    prompt,
    responses,
    isLoading,
    setPrompt,
    showPopup,
    botDetails,
    handleClose,
    handleSubmit,
    currentStream,
    isStreamLoading,
    theme,
    handleRefreshResponse
  } = props;

  const styles = {
    containerBoxStyles: {
      bgcolor: "background.default",
      height: "100vh",
    },
    inputBoxStyles: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "fixed",
      bottom: "25px",
      left: "0px",
      flexDirection: "column",
      alignItems: "center",
    },
    textFieldStyles: {
      backgroundColor: "background.default",
      width: { lg: "1200px", xs: "93%" },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#D3D3D3",
          borderRadius: "28px !important",
        },
        "&:hover fieldset": {
          borderColor: "#D3D3D3",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#D3D3D3",
        },
      },
    },
    inputStyles: {
      color: "text.secondary",
      height: "40px",
    },
    inputAdornmentStyles: {
      mr: 1,
    },
  };

  const DotsWrapper = styled(Box)({
    mt:5,
    display: "flex",
    alignItems: "end",
    justifyContent: "left",
    "& div": {
      width: 15,
      height: 15,
      margin: 4,
      borderRadius: "50%",
      backgroundColor: "#0692E2",
      animation: "dotBounce 1.2s infinite ease-in-out both",
    },
    "& div:nth-of-type(1)": {
      animationDelay: "-0.32s",
    },
    "& div:nth-of-type(2)": {
      animationDelay: "-0.16s",
    },
    "@keyframes dotBounce": {
      "0%, 80%, 100%": {
        transform: "scale(0)",
      },
      "40%": {
        transform: "scale(1)",
      },
    },
  });

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Box sx={styles.containerBoxStyles}>
      <CssBaseline />
      <AppAppBar botDetails={botDetails} theme={theme} />
      <Popup
        botDetails={botDetails}
        showPopup={showPopup}
        handleClose={handleClose}
      />
      <Hero
        botDetails={botDetails}
        responses={responses}
        currentStream={currentStream}
        isLoading={isLoading}
        handleRefreshResponse={handleRefreshResponse}
        handleSubmit={handleSubmit}
      />
      <Box>
        <Box sx={styles.inputBoxStyles}>
          {isLoading && (
            <Box sx={{ width: { lg: "64%", md: "80%", xs: "90%" }, mb:  1 }}>
              <DotsWrapper>
                <Box component="div" />
                <Box component="div" />
                <Box component="div" />
              </DotsWrapper>
            </Box>
          )}
          <TextField
            sx={styles.textFieldStyles}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyPress={handleKeyPress}
            id="outlined-basic"
            hiddenLabel
            size="small"
            variant="outlined"
            aria-label="Enter your email address"
            placeholder="Type something here..."
            inputProps={{
              autoComplete: "off",
              "aria-label": "Enter your email address",
              sx: styles.inputStyles,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={styles.inputAdornmentStyles}>
                  {!isStreamLoading ? (
                    <IconButton edge="end" onClick={handleSubmit}>
                      <SendRoundedIcon fontSize="medium" color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton edge="end" disabled>
                      <StopCircleIcon fontSize="medium" htmlColor="#b3b3b3" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>
          <Box sx={{display:'flex', width:'100%', justifyContent:'center', gap:0.5, mt:1 }} >
        <Typography sx={theme => ({
          
          textAlign: 'center', 
          fontSize: 'small', 
          color: theme.palette.text.secondary,
        })}>
          Powered by 
        </Typography>
        <Box
        component={"img"}
        src={theme.palette.mode === "light" ? '/static/Apex_Logo_2022_large.png' : '/static/Apex_Logo_2022_Large2.png'}
        width={"35px"}
        height={"10px"}
        marginTop={0.4}
        />
        </Box>
          </FormHelperText>
        </Box>
      </Box>
    </Box>
  );
}
